<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form
        ref="form"
        :rules="rules"
        :label-col="labelCol"
        :model="form"
        :wrapper-col="wrapperCol"
        class="form"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="真实姓名" name="realName">
              <a-input
                placeholder="请输入真实姓名"
                v-model:value="form.realName"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机号" name="username">
              <a-input
                placeholder="请输入手机号"
                v-model:value="form.username"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商" name="operatorId">
              <a-select
                v-model:value="form.operatorId"
                placeholder="请选择所属运营商"
              >
                <a-select-option
                  v-for="item in operatorList"
                  :key="item.operatorId"
                  :value="item.operatorId"
                >
                  {{ item.operatorName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属租赁点" name="pointId">
              <a-select
                v-model:value="form.pointId"
                placeholder="请选择所属租赁点"
              >
                <a-select-option
                  v-for="item in pointList"
                  :key="item.pointId"
                  :value="item.pointId"
                >
                  {{ item.pointName }}
                </a-select-option>
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="流水类型" name="billType">
              <a-select
                v-model:value="form.billType"
                placeholder="请选择流水类型"
              >
                <a-select-option
                  :value="item.dictDataCode"
                  v-for="(item, index) in billTypeList"
                  :key="index"
                  >{{ item.dictDataName }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="关联订单" name="linkId">
              <a-input
                placeholder="请输入关联订单"
                v-model:value="form.linkId"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="资金来源" name="payType">
              <a-select
                v-model:value="form.payType"
                placeholder="请选择资金来源"
              >
                <a-select-option
                  :value="item.dictDataCode"
                  v-for="(item, index) in payTypeList"
                  :key="index"
                  >{{ item.dictDataName }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="资金流水单号" name="billNumber">
              <a-input
                placeholder="请输入资金流水单号"
                v-model:value="form.billNumber"
              ></a-input> </a-form-item
          ></a-col>
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="时间:">
              <a-range-picker
                v-model:value="dateRange"
                :show-time="true"
                value-format="YYYY-MM-DD HH:mm:ss"
                class="ud-fluid"
              >
                <template #suffixIcon>
                  <calendar-outlined />
                </template> </a-range-picker></a-form-item
          ></a-col>
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"
              ><i class="iconfont icon-zaitu"></i>查询</a-button
            >
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
            <span
              v-if="!expand"
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              展开<i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span
              v-else
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              收起<i class="iconfont icon-xiangshang_jiantou"></i>
            </span>
          </a-space>
        </a-row>
      </a-form>
    </a-card>
    <a-card :bordered="true" class="table">
      <div>
        <ud-pro-table
          ref="table"
          :rowKey="(record) => record.billId"
          :columns="columns"
          :datasource="dataSource"
          @change="handleChange"
          :scroll="{ x: 'max-content' }"
        >
          <template #toolbar>
          </template>
          <template #orderCode="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.orderCode }}
            </div>
          </template>
          <template #payType="{record}">
            {{
              record.payType === 'weixin'
                ? '微信支付' :'系统内部'
            }}
          </template>
          <template #batteryCode="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.batteryCode }}
            </div>
          </template>
          <template #point="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.point }}
            </div>
          </template>
          <template #customTitle>
            <div>
              所属运营商
              <i
                class="iconfont icon-bukejian"
                style="cursor: pointer"
                v-if="!isShowOperator"
                @click="isShowOperator = true"
              ></i>
              <i
                class="iconfont icon-kejian"
                style="cursor: pointer"
                v-else
                @click="isShowOperator = false"
              ></i>
            </div>
          </template>
          <template #operatorCustom="{ record }">
            <div v-if="!isShowOperator">
              <!-- {{
                record.operator.substring(0, 1) +
                new Array(record.operator.length).join('*')
              }} -->
            </div>
            <div v-else>
              {{ record.operator }}
            </div>
          </template>
        </ud-pro-table>
      </div>
    </a-card>

    <!-- <operation-detail
      v-model:visible="showDetail"
      :data="current || {}"
    ></operation-detail>
    <operation-edit v-model:visible="showEdit" :data="current" @done="reload" /> -->
  </div>
</template>

<script>
// import operationDetail from './operation-detail.vue'
// import operationEdit from './operation-edit.vue'
import { CalendarOutlined } from '@ant-design/icons-vue'
import * as operatorApi from '@/api/rnt/operator'
import * as pointApi from '@/api/rnt/point.js'
import * as userBalanceApi from '@/api/sys/userBalanceBill.js'
export default {
  components: {
    CalendarOutlined
    // DownOutlined,
    // ExclamationCircleOutlined
    // operationDetail,
    // operationEdit
  },
  data() {
    return {
      billTypeList: [],
      payTypeList: [],
      operatorList: [],
      pointList: [],
      visibleLog: false,
      visibleOrder: false,
      visibleEndTime: false,
      visibleRefund: false,
      visibleBatteryCode: false,
      actionForm: { endTime: '' },
      labelCol: { md: { span: 6 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      showDetail: false,
      showEdit: false,
      current: null,
      form: {},
      rules: {},
      expand: false,
      isShowOperator: false,
      columns: [
        {
          title: '序号',
          width: 48,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '手机号',
          // width: 170,
          align: 'center',
          dataIndex: 'username'
        },
        {
          title: '真实姓名',
          // width: 85,
          // align: 'center',
          dataIndex: 'realName'
        },
        {
          title: '流水类型',
          // width: 90,
          // align: 'center',
          dataIndex: 'billType'
        },
        {
          title: '备注',
          // width: 175,
          // align: 'center',
          // dataIndex: 'payType'
          dataIndex: 'remark'
        },
        {
          title: '关联订单',
          // width: 175,
          // align: 'center',
          dataIndex: 'linkId'
        },
        {
          title: '资金来源',
          // width: 175,
          // align: 'center',
          // dataIndex: 'payType'
          slots: {
            customRender: 'payType'
          }
        },
        {
          title: '金额',
          // width: 175,
          // align: 'center',
          dataIndex: 'billFee'
        },
        {
          title: '资金流水单号',
          // width: 175,
          // align: 'center',
          dataIndex: 'billNumber'
        },        
        {
          title: '时间',
          // width: 175,
          // align: 'center'
          dataIndex: 'billTime'
        },
        {
          // width: 175,
          // align: 'center',
          dataIndex: 'operatorName',
          slots: {
            title: 'customTitle',
            customRender: 'operatorCustom'
          }
        },
        {
          title: '租赁点',
          // width: 175,
          // align: 'center',
          dataIndex: 'pointName'
        }

        // {
        //   title: '操作',
        //   width: 200,
        //   dataIndex: 'action',
        //   align: 'center',
        //   slots: {
        //     customRender: 'action'
        //   }
        // }
      ],
      dateRange: []
    }
  },
  watch: {
    dateRange() {
      if (this.dateRange && this.dateRange.length === 2) {
        this.form.billTimeStart = this.dateRange[0]
        this.form.billTimeEnd = this.dateRange[1]
      } else {
        this.form.billTimeStart = null
        this.form.billTimeEnd = null
      }
    }
  },
  mounted() {
    this.queryOperater()
    this.queryPoint()
    this.queryBillType()
    this.queryPayType()
  },
  methods: {
    /* 查询流水类型 */
    queryBillType() {
      userBalanceApi
        .billType()
        .then((res) => {
          if (res.code === 0) {
            this.billTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询支付方式 */
    queryPayType() {
      userBalanceApi
        .payType()
        .then((res) => {
          if (res.code === 0) {
            this.payTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询所属租赁点 */
    queryPoint() {
      pointApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询运营商 */
    queryOperater() {
      operatorApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 获取表格数据
    dataSource(option, callback) {
      userBalanceApi
        .page({
          ...this.form,
          sort: 'billId',
          order: 'desc',
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.form = {}
      this.dateRange = []
      this.$nextTick(() => {
        this.reload()
      })
    },
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    openEdit(row) {
      console.log(row)
      this.current = row
      this.showEdit = true
    },
    // 新增计费模板确定按钮回调事件
    handleBillingOk() {
      this.$refs.billingForm.validate().then((res) => {
        console.log(res)
        this.$message.success('添加计费模板成功！')
        this.visible = false
      })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() {}
  }
}
</script>
<style lang='less' scoped>
.form {
  .btn-wrap {
    padding-right: 20px;
    .iconfont {
      margin-right: 5px;
    }
  }
}
.table {
  margin: 10px 0;
}
</style>
